import { Divider, IconButton, Menu, MenuItem, PopoverOrigin, Tooltip } from '@mui/material';
import React, { forwardRef, isValidElement, ReactNode, useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import cn from 'classnames';
import { DownloadIcon } from '@heroicons/react/solid';
import { MoreMenuContext } from '@components/MoreMenuContext';
import Button, { LoadableButtonProps as ButtonProps } from '@components/Button';
import { MoreVerticalDots } from '@components/icons/custom/MoreVerticalDots';

export type Linkable = {
    href: string;
    newTab?: boolean;
};

export type Clickable = {
    onClick: () => Promise<void> | void | unknown;
};

export const useMoreMenuStyles = {
    outlineButton: {
        border: '1px solid rgb(224, 231, 255)',
        minWidth: 0,
        padding: 12,
        background: 'white',
        backgroundColor: 'white',
    },
    outlineButtonSmall: {
        border: '1px solid rgb(224, 231, 255)',
        minWidth: 0,
        padding: 7,
        background: 'white',
        backgroundColor: 'white',
    },
    paper: {
        borderRadius: '8px',
        border: '1px solid #CED4FB',
        boxShadow: '0 0 16px 0 rgba(0,0,0,.08)',
    },
    menuItem: {
        color: '#616ECF',
    },
    selected: {},
    hover: {},
};

export type MenuItemAction = Linkable | Clickable;
export type DividerItem = { divider?: boolean; label?: string };
export type Item =
    | DividerItem
    | (MenuItemAction & {
          label: ReactNode;
          icon?: ReactNode;
          /**
           * Close the item when selected. Defaults to true
           */
          closeOnClick?: boolean;
          color?: 'default' | 'danger' | 'danger-light';
          disabled?: boolean;
          tooltip?: string | ReactNode | null;
      });

function isDividerItem(item: Item): item is DividerItem {
    return !!(item as DividerItem)?.divider;
}

function isLinkable(item: Item | Linkable): item is Linkable {
    return !!(item as Linkable).href;
}

type MoreMenuListItemProps = { item: Item; handleClose?: () => void; className?: string };

export const MoreMenuListItem = forwardRef<HTMLLIElement, MoreMenuListItemProps>(
    (props: MoreMenuListItemProps, ref) => {
        const { item, handleClose = () => undefined, className } = props;
        if (isDividerItem(item)) {
            return <Divider />;
        }
        const { asPath } = useRouter();
        const $icon = item.icon && <span className="mr-2">{item.icon}</span>;

        const { closeOnClick = false } = item;

        const handleClick = async () => {
            if (!isLinkable(item)) {
                await item.onClick();
                handleClose();
            }
            if (closeOnClick) {
                handleClose();
            }
        };

        if (isLinkable(item)) {
            return (
                <Tooltip
                    title={item.tooltip ?? false}
                    disableFocusListener={!item.tooltip}
                    disableHoverListener={!item.tooltip}
                    disableTouchListener={!item.tooltip}
                    arrow
                    placement="bottom-start"
                >
                    <li ref={ref}>
                        <Link href={item.href} passHref legacyBehavior>
                            <MenuItem
                                onClick={handleClick}
                                component="a"
                                target={item.newTab ? '_blank' : undefined}
                                rel={item.newTab ? 'noreferrer' : undefined}
                                selected={asPath === item.href}
                                disabled={item.disabled}
                                sx={{ selected: useMoreMenuStyles.selected, root: useMoreMenuStyles.menuItem }}
                                className={className}
                            >
                                {$icon}
                                {item.label}
                            </MenuItem>
                        </Link>
                    </li>
                </Tooltip>
            );
        }
        return (
            <Tooltip
                title={item.tooltip ?? ''}
                disableFocusListener={!item.tooltip}
                disableHoverListener={!item.tooltip}
                disableTouchListener={!item.tooltip}
                arrow
                placement="bottom-start"
            >
                <span>
                    <MenuItem
                        ref={ref}
                        onClick={handleClick}
                        sx={{
                            selected: useMoreMenuStyles.selected,
                            root: useMoreMenuStyles.menuItem,
                        }}
                        disabled={item.disabled}
                        className={className}
                    >
                        <span
                            className={cn('flex flex-1 items-center', {
                                'text-error-light': item.color === 'danger-light',
                                'text-error': item.color === 'danger',
                            })}
                        >
                            {$icon}
                            {item.label}
                        </span>
                    </MenuItem>
                </span>
            </Tooltip>
        );
    },
);
MoreMenuListItem.displayName = 'MoreMenuListItem';

export const isItem = (item: Item | ReactNode): item is Item => {
    return !isValidElement(item);
};
export type MoreMenuItem = Item | ReactNode;
type MoreMenuProps = {
    items: MoreMenuItem[];
    handleClose: () => void;
    anchorEl: Element | null;
    anchorOrigin?: PopoverOrigin;
    transformOrigin?: PopoverOrigin;
};
export const MoreMenu = ({ items, handleClose, anchorEl, anchorOrigin, transformOrigin }: MoreMenuProps) => {
    return (
        <MoreMenuContext.Provider value={{ open: !!anchorEl, handleClose: handleClose }}>
            <MoreMenuContext.Consumer>
                {({ open, handleClose }) => (
                    <Menu
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        sx={{ paper: useMoreMenuStyles.paper }}
                        transformOrigin={transformOrigin}
                        anchorOrigin={anchorOrigin}
                    >
                        {items.map((item, i) =>
                            isItem(item) ? (
                                <MoreMenuListItem key={`menu_item_${i}`} item={item} handleClose={handleClose} />
                            ) : (
                                <div key={`menu_item_${i}`}>{item}</div>
                            ),
                        )}
                    </Menu>
                )}
            </MoreMenuContext.Consumer>
        </MoreMenuContext.Provider>
    );
};

export type MoreMenuButtonProps = {
    anchorOrigin?: PopoverOrigin;
    children?: ReactNode;
    className?: string;
    disabled?: boolean;
    downloadIcon?: boolean;
    fullButton?: boolean;
    items: (Item | ReactNode)[];
    onMenuClose?: () => void;
    onMenuOpen?: () => void;
    outlined?: boolean;
    preventDefault?: boolean;
    showWhenEmpty?: boolean;
    size?: 'small' | 'medium';
    transformOrigin?: PopoverOrigin;
};
const MoreMenuIconButton = ({
    anchorOrigin,
    children,
    className,
    disabled,
    downloadIcon = false,
    fullButton = false,
    items,
    onMenuClose,
    onMenuOpen,
    outlined = false,
    preventDefault = true,
    showWhenEmpty = false,
    size,
    transformOrigin,
    ...fullButtonProps
}: MoreMenuButtonProps & Pick<ButtonProps, 'color' | 'variant' | 'loading' | 'startIcon' | 'endIcon'>) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        onMenuOpen?.();
        if (preventDefault) {
            event.preventDefault();
        }

        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        onMenuClose?.();
    };

    if (!showWhenEmpty && items.length === 0) {
        return null;
    }

    return (
        <>
            {fullButton ? (
                <Button
                    size={size}
                    disabled={disabled}
                    onClick={handleClick}
                    startIcon={downloadIcon && <DownloadIcon className="h-5 w-5" />}
                    {...fullButtonProps}
                >
                    {children}
                </Button>
            ) : (
                <div
                    className={cn('rounded-full', {
                        'border border-indigo-100 bg-white': !!outlined,
                    })}
                >
                    <IconButton
                        sx={{ '& .MuiIconButton-label': { lineHeight: 1 } }}
                        size={size}
                        disabled={disabled}
                        onClick={handleClick}
                        className={cn('text-indigo-500', className, {
                            'p-[11px]': !!outlined && size !== 'small',
                            'p-[7px]': !!outlined && size === 'small',
                        })}
                    >
                        {downloadIcon ? (
                            <DownloadIcon className="h-5 w-5 text-indigo-500" />
                        ) : (
                            <MoreVerticalDots height={18} width={18} fontSize="small" />
                        )}
                    </IconButton>
                </div>
            )}
            <MoreMenu
                items={items}
                handleClose={handleClose}
                anchorEl={anchorEl}
                anchorOrigin={anchorOrigin}
                transformOrigin={transformOrigin}
            />
        </>
    );
};

export default MoreMenuIconButton;
