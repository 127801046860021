import AvatarUploadCircle, { UploadVariant } from '@components/profile/AvatarUploadCircle';
import { ReactNode, useCallback, useEffect } from 'react';
import { blankToNull } from '@util/StringUtil';
import { useDropzone } from 'react-dropzone';
import cn from 'classnames';
import Logger from '@util/Logger';
import useAvatarUpload from '@hooks/useAvatarUpload';
import { DjangoError } from '@/src/api/ApiTypes';

const logger = Logger.make('AvatarUploadCircleFormField');

type Props = {
    value: string | null | undefined;
    endpoint?: string;
    className?: string;
    title?: string;
    avatarId?: string;
    children?: ReactNode;
    variant?: UploadVariant;
    onUploadError?: (error: DjangoError | null) => void;
    updateResult: (result: string | null) => void;
    disabled?: boolean;
};
const AvatarUploadCircleFormField = ({
    value,
    className,
    title,
    avatarId,
    children,
    variant = 'rounded',
    onUploadError,
    updateResult,
    disabled,
}: Props) => {
    const { progress, uploading, uploadAvatarImage, reset, error: uploadError } = useAvatarUpload();

    useEffect(() => {
        onUploadError?.(uploadError);
    }, [uploadError]);

    const handleImageFiles = async (files?: FileList | File[] | null) => {
        const file = files?.[0];
        if (!files || files.length === 0 || !file) {
            updateResult(null);
            reset();
            return;
        }

        const result = await uploadAvatarImage(file);
        if (result && 'avatar_url' in result) {
            const url = result?.avatar_url;
            updateResult(url);
        }
        return;
    };

    const onDrop = useCallback((acceptedFiles: File[]) => {
        logger.debug('files dropped', acceptedFiles);
        handleImageFiles(acceptedFiles);
    }, []);

    const { getRootProps, getInputProps, isDragActive, open: openFilePicker } = useDropzone({ onDrop, noClick: true });

    return (
        <div
            {...getRootProps()}
            className={cn('-mx-2 rounded-xl px-2 py-2', className, {
                'border-2 border-dashed border-indigo-300 ': isDragActive,
            })}
        >
            <div>
                <div className="relative inline-block">
                    <AvatarUploadCircle
                        key={avatarId}
                        imageUrl={blankToNull(value)}
                        onChange={handleImageFiles}
                        title={title}
                        avatarId={avatarId}
                        uploading={uploading}
                        progress={progress}
                        variant={variant}
                        disabled={disabled}
                    />
                </div>
            </div>
            <div onClick={openFilePicker} className="cursor-pointer">
                {children}
            </div>
            <input {...getInputProps()} />
        </div>
    );
};

export default AvatarUploadCircleFormField;
