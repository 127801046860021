import React, { Component } from 'react';
import { AvatarCircle } from '@components/AvatarCircle';
import { CircularProgress } from '@mui/material';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import { ProgressEventInfo } from '@services/ApiService';
import { User } from '@models/User';
import cn from 'classnames';

export type UploadVariant = 'rounded' | 'circle';

type AvatarUploadCircleProps = {
    onChange?: (files: FileList | null) => void;
    user?: User | null;
    progress?: ProgressEventInfo | null;
    uploading?: boolean;
    imageUrl?: string | null;
    title?: string;
    disabled?: boolean;
    avatarId?: string;
    variant?: UploadVariant;
};
type AvatarUploadCircleState = Record<string, never>;

class AvatarUploadCircle extends Component<AvatarUploadCircleProps, AvatarUploadCircleState> {
    render() {
        const {
            uploading,
            imageUrl,
            title,
            onChange,
            progress,
            user,
            disabled = false,
            avatarId,
            variant = 'circle',
        } = this.props;

        if (imageUrl && !uploading) {
            return (
                <div className="relative">
                    <AvatarCircle imageUrl={imageUrl} size="lg" userId={user?.uuid} disableTooltip variant={variant} />
                    {!disabled && (
                        <div className="absolute -right-2 -top-2">
                            <span
                                onClick={() => onChange?.(null)}
                                aria-label="delete"
                                className={cn(
                                    'flex h-8 w-8 cursor-pointer items-center justify-center rounded-full border-red-500 bg-white text-red-500 shadow hover:bg-red-500 hover:text-white',
                                )}
                            >
                                {imageUrl ? (
                                    <DeleteOutlineRoundedIcon fontSize="small" />
                                ) : (
                                    <CameraAltOutlinedIcon fontSize="small" />
                                )}
                            </span>
                        </div>
                    )}
                </div>
            );
        }

        return (
            <div className="relative">
                {uploading && (
                    <div
                        className={cn(
                            'relative inline-flex h-16 w-16 items-center justify-center bg-gray-100 p-2 md:h-20 md:w-20',
                            {
                                'rounded-full': variant === 'circle',
                                'rounded-lg': variant === 'rounded',
                            },
                        )}
                    >
                        <CircularProgress
                            variant="determinate"
                            value={(progress?.percentage ?? 0) * 100}
                            color="primary"
                            size={60}
                        />
                        {progress && (
                            <div className="absolute ">
                                <span>{`${Math.round(progress.percentage * 100)}%`}</span>
                            </div>
                        )}
                    </div>
                )}
                {!uploading && (
                    <>
                        <AvatarCircle
                            imageUrl={imageUrl}
                            size="lg"
                            title={title}
                            userId={user?.uuid ?? avatarId}
                            disableTooltip
                            variant={variant}
                        />
                        {!disabled && (
                            <div className="absolute -right-2 -top-2">
                                <label
                                    htmlFor="file-upload"
                                    className="flex h-8 w-8 cursor-pointer items-center justify-center rounded-full border-indigo-500 bg-white text-indigo-500 shadow hover:bg-indigo-200"
                                >
                                    <CameraAltOutlinedIcon className="fill-current text-indigo-400" fontSize="small" />
                                    <input
                                        id="file-upload"
                                        name="file-upload"
                                        type="file"
                                        className="sr-only"
                                        onChange={(e) => onChange?.(e.target.files)}
                                    />
                                </label>
                            </div>
                        )}
                    </>
                )}
            </div>
        );
    }
}

export default AvatarUploadCircle;
