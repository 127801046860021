import useApi from '@hooks/useApi';
import { useState } from 'react';
import { ProgressEventInfo } from '@services/ApiService';
import { AvatarImageUploadResponse } from '@api/user/UserApiTypes';
import Logger from '@util/Logger';
import { ApiResponse, DjangoError } from '@api/ApiTypes';

const logger = Logger.make('useAvatarUpload');

/**
 * React hook to use upload service
 */
export const useAvatarUpload = () => {
    const { apiService } = useApi();

    const [uploading, setUploading] = useState(false);
    const [progress, setProgress] = useState<ProgressEventInfo | null>(null);
    const [error, setError] = useState<DjangoError | null>(null);

    const reset = () => {
        setError(null);
        setUploading(false);
        setProgress(null);
    };

    const uploadAvatarImage = async (
        file?: File | null,
    ): Promise<AvatarImageUploadResponse | ApiResponse<string> | null> => {
        if (!file) {
            return null;
        }

        setUploading(true);
        setProgress(null);
        const response: AvatarImageUploadResponse | ApiResponse<string, DjangoError> | null =
            await apiService.uploadAvatar(file, {
                onProgress: (e) => setProgress(e),
            });

        setUploading(false);
        if (response && 'error' in response) {
            logger.error(response.error);
            setError(response.error);
            return null;
        }
        return response;
    };

    return { uploadAvatarImage, uploading, progress, error, reset };
};

export default useAvatarUpload;
